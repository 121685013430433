@import '../../../../styles/var.scss';

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: rgb(245, 251, 255);
  cursor: pointer;
  margin-top: 6px;
  border-radius: 6px;
  .card-title {
    color: $primary-color;
  }
  &:hover {
    opacity: .65;
  }
}