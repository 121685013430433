@import '../../styles/var.scss';

.copy-container {
  position: relative;
  display: flex;
  align-items: center;
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.tooltip {
  position: absolute;
  bottom: -22px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #191326;
  color: #fff;
  border-radius: 16px;
  opacity: 0.7;
}