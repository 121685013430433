@import '../../../../styles/var.scss';

.account {
  color: rgb(74, 74, 74);
  font-weight: 600;
  line-height: 1.5;
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
  cursor: pointer;
}
.account-ellipsis {
  display: none;
}

@media screen and (max-width: 800px) {
  .account {
      display: none;
  }
  .account-ellipsis {
    display: block;
    font-size: 16px;
    color: rgb(74, 74, 74);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 8px;
    font-weight: 600;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  .view {
    display: flex;
    align-items: center;
    margin-right: 16px;
    color: $primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
}

.logout {
  display: flex;
  justify-content: center;
}