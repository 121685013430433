@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: rotate 2s linear infinite;
}

.svg {
  align-self: center;
  flex-shrink: 0;
  margin: 0 3px;
}