.connect-btn {
  padding: 22px 48px;
  font-size: 24px;
  opacity: 1;
  background: linear-gradient(180deg,#40ccfd, #05bffc); 
  color: #fff;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    opacity: .9;
  }
}