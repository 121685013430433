.layout {
  height: 100%;
  color: #333;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    line-height: 80px;
    padding: 0 40px;
    border-bottom: 2px solid rgba(133, 133, 133, 0.1);
    background: #fff;
  }
  .content {
    padding: 40px;
  }
}